// ../../../node_modules/systemjs/dist/system.js
(function() {
  function errMsg(errCode, msg) {
    return (msg || "") + " (SystemJS Error#" + errCode + " https://github.com/systemjs/systemjs/blob/main/docs/errors.md#" + errCode + ")";
  }
  var hasSymbol = typeof Symbol !== "undefined";
  var hasSelf = typeof self !== "undefined";
  var hasDocument = typeof document !== "undefined";
  var envGlobal = hasSelf ? self : global;
  var baseUrl;
  if (hasDocument) {
    var baseEl = document.querySelector("base[href]");
    if (baseEl)
      baseUrl = baseEl.href;
  }
  if (!baseUrl && typeof location !== "undefined") {
    baseUrl = location.href.split("#")[0].split("?")[0];
    var lastSepIndex = baseUrl.lastIndexOf("/");
    if (lastSepIndex !== -1)
      baseUrl = baseUrl.slice(0, lastSepIndex + 1);
  }
  var backslashRegEx = /\\/g;
  function resolveIfNotPlainOrUrl(relUrl, parentUrl) {
    if (relUrl.indexOf("\\") !== -1)
      relUrl = relUrl.replace(backslashRegEx, "/");
    if (relUrl[0] === "/" && relUrl[1] === "/") {
      return parentUrl.slice(0, parentUrl.indexOf(":") + 1) + relUrl;
    } else if (relUrl[0] === "." && (relUrl[1] === "/" || relUrl[1] === "." && (relUrl[2] === "/" || relUrl.length === 2 && (relUrl += "/")) || relUrl.length === 1 && (relUrl += "/")) || relUrl[0] === "/") {
      var parentProtocol = parentUrl.slice(0, parentUrl.indexOf(":") + 1);
      var pathname;
      if (parentUrl[parentProtocol.length + 1] === "/") {
        if (parentProtocol !== "file:") {
          pathname = parentUrl.slice(parentProtocol.length + 2);
          pathname = pathname.slice(pathname.indexOf("/") + 1);
        } else {
          pathname = parentUrl.slice(8);
        }
      } else {
        pathname = parentUrl.slice(parentProtocol.length + (parentUrl[parentProtocol.length] === "/"));
      }
      if (relUrl[0] === "/")
        return parentUrl.slice(0, parentUrl.length - pathname.length - 1) + relUrl;
      var segmented = pathname.slice(0, pathname.lastIndexOf("/") + 1) + relUrl;
      var output = [];
      var segmentIndex = -1;
      for (var i = 0; i < segmented.length; i++) {
        if (segmentIndex !== -1) {
          if (segmented[i] === "/") {
            output.push(segmented.slice(segmentIndex, i + 1));
            segmentIndex = -1;
          }
        } else if (segmented[i] === ".") {
          if (segmented[i + 1] === "." && (segmented[i + 2] === "/" || i + 2 === segmented.length)) {
            output.pop();
            i += 2;
          } else if (segmented[i + 1] === "/" || i + 1 === segmented.length) {
            i += 1;
          } else {
            segmentIndex = i;
          }
        } else {
          segmentIndex = i;
        }
      }
      if (segmentIndex !== -1)
        output.push(segmented.slice(segmentIndex));
      return parentUrl.slice(0, parentUrl.length - pathname.length) + output.join("");
    }
  }
  function resolveUrl(relUrl, parentUrl) {
    return resolveIfNotPlainOrUrl(relUrl, parentUrl) || (relUrl.indexOf(":") !== -1 ? relUrl : resolveIfNotPlainOrUrl("./" + relUrl, parentUrl));
  }
  function resolveAndComposePackages(packages, outPackages, baseUrl2, parentMap, parentUrl) {
    for (var p in packages) {
      var resolvedLhs = resolveIfNotPlainOrUrl(p, baseUrl2) || p;
      var rhs = packages[p];
      if (typeof rhs !== "string")
        continue;
      var mapped = resolveImportMap(parentMap, resolveIfNotPlainOrUrl(rhs, baseUrl2) || rhs, parentUrl);
      if (!mapped) {
        targetWarning("W1", p, rhs, "bare specifier did not resolve");
      } else
        outPackages[resolvedLhs] = mapped;
    }
  }
  function resolveAndComposeImportMap(json, baseUrl2, outMap) {
    if (json.imports)
      resolveAndComposePackages(json.imports, outMap.imports, baseUrl2, outMap, null);
    var u;
    for (u in json.scopes || {}) {
      var resolvedScope = resolveUrl(u, baseUrl2);
      resolveAndComposePackages(json.scopes[u], outMap.scopes[resolvedScope] || (outMap.scopes[resolvedScope] = {}), baseUrl2, outMap, resolvedScope);
    }
    for (u in json.depcache || {})
      outMap.depcache[resolveUrl(u, baseUrl2)] = json.depcache[u];
    for (u in json.integrity || {})
      outMap.integrity[resolveUrl(u, baseUrl2)] = json.integrity[u];
  }
  function getMatch(path, matchObj) {
    if (matchObj[path])
      return path;
    var sepIndex = path.length;
    do {
      var segment = path.slice(0, sepIndex + 1);
      if (segment in matchObj)
        return segment;
    } while ((sepIndex = path.lastIndexOf("/", sepIndex - 1)) !== -1);
  }
  function applyPackages(id, packages) {
    var pkgName = getMatch(id, packages);
    if (pkgName) {
      var pkg = packages[pkgName];
      if (pkg === null) return;
      if (id.length > pkgName.length && pkg[pkg.length - 1] !== "/") {
        targetWarning("W2", pkgName, pkg, "should have a trailing '/'");
      } else
        return pkg + id.slice(pkgName.length);
    }
  }
  function targetWarning(code, match, target, msg) {
    console.warn(errMsg(code, "Package target " + msg + ", resolving target '" + target + "' for " + match));
  }
  function resolveImportMap(importMap2, resolvedOrPlain, parentUrl) {
    var scopes = importMap2.scopes;
    var scopeUrl = parentUrl && getMatch(parentUrl, scopes);
    while (scopeUrl) {
      var packageResolution = applyPackages(resolvedOrPlain, scopes[scopeUrl]);
      if (packageResolution)
        return packageResolution;
      scopeUrl = getMatch(scopeUrl.slice(0, scopeUrl.lastIndexOf("/")), scopes);
    }
    return applyPackages(resolvedOrPlain, importMap2.imports) || resolvedOrPlain.indexOf(":") !== -1 && resolvedOrPlain;
  }
  var toStringTag$1 = hasSymbol && Symbol.toStringTag;
  var REGISTRY = hasSymbol ? Symbol() : "@";
  function SystemJS() {
    this[REGISTRY] = {};
  }
  var systemJSPrototype = SystemJS.prototype;
  systemJSPrototype.import = function(id, parentUrl, meta) {
    var loader8 = this;
    parentUrl && typeof parentUrl === "object" && (meta = parentUrl, parentUrl = void 0);
    return Promise.resolve(loader8.prepareImport()).then(function() {
      return loader8.resolve(id, parentUrl, meta);
    }).then(function(id2) {
      var load = getOrCreateLoad(loader8, id2, void 0, meta);
      return load.C || topLevelLoad(loader8, load);
    });
  };
  systemJSPrototype.createContext = function(parentId) {
    var loader8 = this;
    return {
      url: parentId,
      resolve: function(id, parentUrl) {
        return Promise.resolve(loader8.resolve(id, parentUrl || parentId));
      }
    };
  };
  systemJSPrototype.onload = function() {
  };
  function loadToId(load) {
    return load.id;
  }
  function triggerOnload(loader8, load, err, isErrSource) {
    loader8.onload(err, load.id, load.d && load.d.map(loadToId), !!isErrSource);
    if (err)
      throw err;
  }
  var lastRegister;
  systemJSPrototype.register = function(deps, declare, metas) {
    lastRegister = [deps, declare, metas];
  };
  systemJSPrototype.getRegister = function() {
    var _lastRegister = lastRegister;
    lastRegister = void 0;
    return _lastRegister;
  };
  function getOrCreateLoad(loader8, id, firstParentUrl, meta) {
    var load = loader8[REGISTRY][id];
    if (load)
      return load;
    var importerSetters = [];
    var ns = /* @__PURE__ */ Object.create(null);
    if (toStringTag$1)
      Object.defineProperty(ns, toStringTag$1, { value: "Module" });
    var instantiatePromise = Promise.resolve().then(function() {
      return loader8.instantiate(id, firstParentUrl, meta);
    }).then(function(registration) {
      if (!registration)
        throw Error(errMsg(2, "Module " + id + " did not instantiate"));
      function _export(name, value) {
        load.h = true;
        var changed = false;
        if (typeof name === "string") {
          if (!(name in ns) || ns[name] !== value) {
            ns[name] = value;
            changed = true;
          }
        } else {
          for (var p in name) {
            var value = name[p];
            if (!(p in ns) || ns[p] !== value) {
              ns[p] = value;
              changed = true;
            }
          }
          if (name && name.__esModule) {
            ns.__esModule = name.__esModule;
          }
        }
        if (changed)
          for (var i = 0; i < importerSetters.length; i++) {
            var setter = importerSetters[i];
            if (setter) setter(ns);
          }
        return value;
      }
      var declared = registration[1](_export, registration[1].length === 2 ? {
        import: function(importId, meta2) {
          return loader8.import(importId, id, meta2);
        },
        meta: loader8.createContext(id)
      } : void 0);
      load.e = declared.execute || function() {
      };
      return [registration[0], declared.setters || [], registration[2] || []];
    }, function(err) {
      load.e = null;
      load.er = err;
      triggerOnload(loader8, load, err, true);
      throw err;
    });
    var linkPromise = instantiatePromise.then(function(instantiation) {
      return Promise.all(instantiation[0].map(function(dep, i) {
        var setter = instantiation[1][i];
        var meta2 = instantiation[2][i];
        return Promise.resolve(loader8.resolve(dep, id)).then(function(depId) {
          var depLoad = getOrCreateLoad(loader8, depId, id, meta2);
          return Promise.resolve(depLoad.I).then(function() {
            if (setter) {
              depLoad.i.push(setter);
              if (depLoad.h || !depLoad.I)
                setter(depLoad.n);
            }
            return depLoad;
          });
        });
      })).then(function(depLoads) {
        load.d = depLoads;
      });
    });
    return load = loader8[REGISTRY][id] = {
      id,
      // importerSetters, the setters functions registered to this dependency
      // we retain this to add more later
      i: importerSetters,
      // module namespace object
      n: ns,
      // extra module information for import assertion
      // shape like: { assert: { type: 'xyz' } }
      m: meta,
      // instantiate
      I: instantiatePromise,
      // link
      L: linkPromise,
      // whether it has hoisted exports
      h: false,
      // On instantiate completion we have populated:
      // dependency load records
      d: void 0,
      // execution function
      e: void 0,
      // On execution we have populated:
      // the execution error if any
      er: void 0,
      // in the case of TLA, the execution promise
      E: void 0,
      // On execution, L, I, E cleared
      // Promise for top-level completion
      C: void 0,
      // parent instantiator / executor
      p: void 0
    };
  }
  function instantiateAll(loader8, load, parent, loaded) {
    if (!loaded[load.id]) {
      loaded[load.id] = true;
      return Promise.resolve(load.L).then(function() {
        if (!load.p || load.p.e === null)
          load.p = parent;
        return Promise.all(load.d.map(function(dep) {
          return instantiateAll(loader8, dep, parent, loaded);
        }));
      }).catch(function(err) {
        if (load.er)
          throw err;
        load.e = null;
        triggerOnload(loader8, load, err, false);
        throw err;
      });
    }
  }
  function topLevelLoad(loader8, load) {
    return load.C = instantiateAll(loader8, load, load, {}).then(function() {
      return postOrderExec(loader8, load, {});
    }).then(function() {
      return load.n;
    });
  }
  var nullContext = Object.freeze(/* @__PURE__ */ Object.create(null));
  function postOrderExec(loader8, load, seen) {
    if (seen[load.id])
      return;
    seen[load.id] = true;
    if (!load.e) {
      if (load.er)
        throw load.er;
      if (load.E)
        return load.E;
      return;
    }
    var exec = load.e;
    load.e = null;
    var depLoadPromises;
    load.d.forEach(function(depLoad) {
      try {
        var depLoadPromise = postOrderExec(loader8, depLoad, seen);
        if (depLoadPromise)
          (depLoadPromises = depLoadPromises || []).push(depLoadPromise);
      } catch (err) {
        load.er = err;
        triggerOnload(loader8, load, err, false);
        throw err;
      }
    });
    if (depLoadPromises)
      return Promise.all(depLoadPromises).then(doExec);
    return doExec();
    function doExec() {
      try {
        var execPromise = exec.call(nullContext);
        if (execPromise) {
          execPromise = execPromise.then(function() {
            load.C = load.n;
            load.E = null;
            if (true) triggerOnload(loader8, load, null, true);
          }, function(err) {
            load.er = err;
            load.E = null;
            if (true) triggerOnload(loader8, load, err, true);
            throw err;
          });
          return load.E = execPromise;
        }
        load.C = load.n;
        load.L = load.I = void 0;
      } catch (err) {
        load.er = err;
        throw err;
      } finally {
        triggerOnload(loader8, load, load.er, true);
      }
    }
  }
  envGlobal.System = new SystemJS();
  var importMapPromise = Promise.resolve();
  var importMap = { imports: {}, scopes: {}, depcache: {}, integrity: {} };
  var processFirst = hasDocument;
  systemJSPrototype.prepareImport = function(doProcessScripts) {
    if (processFirst || doProcessScripts) {
      processScripts();
      processFirst = false;
    }
    return importMapPromise;
  };
  if (hasDocument) {
    processScripts();
    window.addEventListener("DOMContentLoaded", processScripts);
  }
  systemJSPrototype.addImportMap = function(newMap, mapBase) {
    resolveAndComposeImportMap(newMap, mapBase || baseUrl, importMap);
  };
  function processScripts() {
    [].forEach.call(document.querySelectorAll("script"), function(script) {
      if (script.sp)
        return;
      if (script.type === "systemjs-module") {
        script.sp = true;
        if (!script.src)
          return;
        System.import(script.src.slice(0, 7) === "import:" ? script.src.slice(7) : resolveUrl(script.src, baseUrl)).catch(function(e) {
          if (e.message.indexOf("https://github.com/systemjs/systemjs/blob/main/docs/errors.md#3") > -1) {
            var event = document.createEvent("Event");
            event.initEvent("error", false, false);
            script.dispatchEvent(event);
          }
          return Promise.reject(e);
        });
      } else if (script.type === "systemjs-importmap") {
        script.sp = true;
        var fetchPromise = script.src ? (System.fetch || fetch)(script.src, { integrity: script.integrity, passThrough: true }).then(function(res) {
          if (!res.ok)
            throw Error("Invalid status code: " + res.status);
          return res.text();
        }).catch(function(err) {
          err.message = errMsg("W4", "Error fetching systemjs-import map " + script.src) + "\n" + err.message;
          console.warn(err);
          if (typeof script.onerror === "function") {
            script.onerror();
          }
          return "{}";
        }) : script.innerHTML;
        importMapPromise = importMapPromise.then(function() {
          return fetchPromise;
        }).then(function(text) {
          extendImportMap(importMap, text, script.src || baseUrl);
        });
      }
    });
  }
  function extendImportMap(importMap2, newMapText, newMapUrl) {
    var newMap = {};
    try {
      newMap = JSON.parse(newMapText);
    } catch (err) {
      console.warn(Error(errMsg("W5", "systemjs-importmap contains invalid JSON") + "\n\n" + newMapText + "\n"));
    }
    resolveAndComposeImportMap(newMap, newMapUrl, importMap2);
  }
  if (hasDocument) {
    window.addEventListener("error", function(evt) {
      lastWindowErrorUrl = evt.filename;
      lastWindowError = evt.error;
    });
    var baseOrigin = location.origin;
  }
  systemJSPrototype.createScript = function(url) {
    var script = document.createElement("script");
    script.async = true;
    if (url.indexOf(baseOrigin + "/"))
      script.crossOrigin = "anonymous";
    var integrity = importMap.integrity[url];
    if (integrity)
      script.integrity = integrity;
    script.src = url;
    return script;
  };
  var lastAutoImportDeps, lastAutoImportTimeout;
  var autoImportCandidates = {};
  var systemRegister2 = systemJSPrototype.register;
  systemJSPrototype.register = function(deps, declare) {
    if (hasDocument && document.readyState === "loading" && typeof deps !== "string") {
      var scripts = document.querySelectorAll("script[src]");
      var lastScript = scripts[scripts.length - 1];
      if (lastScript) {
        lastScript.src;
        lastAutoImportDeps = deps;
        var loader8 = this;
        lastAutoImportTimeout = setTimeout(function() {
          autoImportCandidates[lastScript.src] = [deps, declare];
          loader8.import(lastScript.src);
        });
      }
    } else {
      lastAutoImportDeps = void 0;
    }
    return systemRegister2.call(this, deps, declare);
  };
  var lastWindowErrorUrl, lastWindowError;
  systemJSPrototype.instantiate = function(url, firstParentUrl) {
    var autoImportRegistration = autoImportCandidates[url];
    if (autoImportRegistration) {
      delete autoImportCandidates[url];
      return autoImportRegistration;
    }
    var loader8 = this;
    return Promise.resolve(systemJSPrototype.createScript(url)).then(function(script) {
      return new Promise(function(resolve, reject) {
        script.addEventListener("error", function() {
          reject(Error(errMsg(3, "Error loading " + url + (firstParentUrl ? " from " + firstParentUrl : ""))));
        });
        script.addEventListener("load", function() {
          document.head.removeChild(script);
          if (lastWindowErrorUrl === url) {
            reject(lastWindowError);
          } else {
            var register = loader8.getRegister(url);
            if (register && register[0] === lastAutoImportDeps)
              clearTimeout(lastAutoImportTimeout);
            resolve(register);
          }
        });
        document.head.appendChild(script);
      });
    });
  };
  systemJSPrototype.shouldFetch = function() {
    return false;
  };
  if (typeof fetch !== "undefined")
    systemJSPrototype.fetch = fetch;
  var instantiate = systemJSPrototype.instantiate;
  var jsContentTypeRegEx = /^(text|application)\/(x-)?javascript(;|$)/;
  systemJSPrototype.instantiate = function(url, parent, meta) {
    var loader8 = this;
    if (!this.shouldFetch(url, parent, meta))
      return instantiate.apply(this, arguments);
    return this.fetch(url, {
      credentials: "same-origin",
      integrity: importMap.integrity[url],
      meta
    }).then(function(res) {
      if (!res.ok)
        throw Error(errMsg(7, res.status + " " + res.statusText + ", loading " + url + (parent ? " from " + parent : "")));
      var contentType = res.headers.get("content-type");
      if (!contentType || !jsContentTypeRegEx.test(contentType))
        throw Error(errMsg(4, 'Unknown Content-Type "' + contentType + '", loading ' + url + (parent ? " from " + parent : "")));
      return res.text().then(function(source) {
        if (source.indexOf("//# sourceURL=") < 0)
          source += "\n//# sourceURL=" + url;
        (0, eval)(source);
        return loader8.getRegister(url);
      });
    });
  };
  systemJSPrototype.resolve = function(id, parentUrl) {
    parentUrl = parentUrl || false || baseUrl;
    return resolveImportMap(importMap, resolveIfNotPlainOrUrl(id, parentUrl) || id, parentUrl) || throwUnresolved(id, parentUrl);
  };
  function throwUnresolved(id, parentUrl) {
    throw Error(errMsg(8, "Unable to resolve bare specifier '" + id + (parentUrl ? "' from " + parentUrl : "'")));
  }
  var systemInstantiate = systemJSPrototype.instantiate;
  systemJSPrototype.instantiate = function(url, firstParentUrl, meta) {
    var preloads = importMap.depcache[url];
    if (preloads) {
      for (var i = 0; i < preloads.length; i++)
        getOrCreateLoad(this, this.resolve(preloads[i], url), url);
    }
    return systemInstantiate.call(this, url, firstParentUrl, meta);
  };
  if (hasSelf && typeof importScripts === "function")
    systemJSPrototype.instantiate = function(url) {
      var loader8 = this;
      return Promise.resolve().then(function() {
        importScripts(url);
        return loader8.getRegister(url);
      });
    };
  (function(global2) {
    var systemJSPrototype2 = global2.System.constructor.prototype;
    var firstGlobalProp, secondGlobalProp, lastGlobalProp;
    function getGlobalProp(useFirstGlobalProp) {
      var cnt = 0;
      var foundLastProp, result;
      for (var p in global2) {
        if (shouldSkipProperty(p))
          continue;
        if (cnt === 0 && p !== firstGlobalProp || cnt === 1 && p !== secondGlobalProp)
          return p;
        if (foundLastProp) {
          lastGlobalProp = p;
          result = useFirstGlobalProp && result || p;
        } else {
          foundLastProp = p === lastGlobalProp;
        }
        cnt++;
      }
      return result;
    }
    function noteGlobalProps() {
      firstGlobalProp = secondGlobalProp = void 0;
      for (var p in global2) {
        if (shouldSkipProperty(p))
          continue;
        if (!firstGlobalProp)
          firstGlobalProp = p;
        else if (!secondGlobalProp)
          secondGlobalProp = p;
        lastGlobalProp = p;
      }
      return lastGlobalProp;
    }
    var impt = systemJSPrototype2.import;
    systemJSPrototype2.import = function(id, parentUrl, meta) {
      noteGlobalProps();
      return impt.call(this, id, parentUrl, meta);
    };
    var emptyInstantiation = [[], function() {
      return {};
    }];
    var getRegister = systemJSPrototype2.getRegister;
    systemJSPrototype2.getRegister = function() {
      var lastRegister2 = getRegister.call(this);
      if (lastRegister2)
        return lastRegister2;
      var globalProp = getGlobalProp(this.firstGlobalProp);
      if (!globalProp)
        return emptyInstantiation;
      var globalExport;
      try {
        globalExport = global2[globalProp];
      } catch (e) {
        return emptyInstantiation;
      }
      return [[], function(_export) {
        return {
          execute: function() {
            _export(globalExport);
            _export({ default: globalExport, __useDefault: true });
          }
        };
      }];
    };
    var isIE11 = typeof navigator !== "undefined" && navigator.userAgent.indexOf("Trident") !== -1;
    function shouldSkipProperty(p) {
      return !global2.hasOwnProperty(p) || !isNaN(p) && p < global2.length || isIE11 && global2[p] && typeof window !== "undefined" && global2[p].parent === window;
    }
  })(typeof self !== "undefined" ? self : global);
  (function(global2) {
    var systemJSPrototype2 = global2.System.constructor.prototype;
    var moduleTypesRegEx = /^[^#?]+\.(css|html|json|wasm)([?#].*)?$/;
    var _shouldFetch = systemJSPrototype2.shouldFetch.bind(systemJSPrototype2);
    systemJSPrototype2.shouldFetch = function(url) {
      return _shouldFetch(url) || moduleTypesRegEx.test(url);
    };
    var jsonContentType = /^application\/json(;|$)/;
    var cssContentType = /^text\/css(;|$)/;
    var wasmContentType = /^application\/wasm(;|$)/;
    var fetch2 = systemJSPrototype2.fetch;
    systemJSPrototype2.fetch = function(url, options) {
      return fetch2(url, options).then(function(res) {
        if (options.passThrough)
          return res;
        if (!res.ok)
          return res;
        var contentType = res.headers.get("content-type");
        if (jsonContentType.test(contentType))
          return res.json().then(function(json) {
            return new Response(new Blob([
              'System.register([],function(e){return{execute:function(){e("default",' + JSON.stringify(json) + ")}}})"
            ], {
              type: "application/javascript"
            }));
          });
        if (cssContentType.test(contentType))
          return res.text().then(function(source) {
            source = source.replace(/url\(\s*(?:(["'])((?:\\.|[^\n\\"'])+)\1|((?:\\.|[^\s,"'()\\])+))\s*\)/g, function(match, quotes, relUrl1, relUrl2) {
              return ["url(", quotes, resolveUrl(relUrl1 || relUrl2, url), quotes, ")"].join("");
            });
            return new Response(new Blob([
              "System.register([],function(e){return{execute:function(){var s=new CSSStyleSheet();s.replaceSync(" + JSON.stringify(source) + ');e("default",s)}}})'
            ], {
              type: "application/javascript"
            }));
          });
        if (wasmContentType.test(contentType))
          return (WebAssembly.compileStreaming ? WebAssembly.compileStreaming(res) : res.arrayBuffer().then(WebAssembly.compile)).then(function(module) {
            if (!global2.System.wasmModules)
              global2.System.wasmModules = /* @__PURE__ */ Object.create(null);
            global2.System.wasmModules[url] = module;
            var deps = [];
            var setterSources = [];
            if (WebAssembly.Module.imports)
              WebAssembly.Module.imports(module).forEach(function(impt) {
                var key = JSON.stringify(impt.module);
                if (deps.indexOf(key) === -1) {
                  deps.push(key);
                  setterSources.push("function(m){i[" + key + "]=m}");
                }
              });
            return new Response(new Blob([
              "System.register([" + deps.join(",") + "],function(e){var i={};return{setters:[" + setterSources.join(",") + "],execute:function(){return WebAssembly.instantiate(System.wasmModules[" + JSON.stringify(url) + "],i).then(function(m){e(m.exports)})}}})"
            ], {
              type: "application/javascript"
            }));
          });
        return res;
      });
    };
  })(typeof self !== "undefined" ? self : global);
  var toStringTag = typeof Symbol !== "undefined" && Symbol.toStringTag;
  systemJSPrototype.get = function(id) {
    var load = this[REGISTRY][id];
    if (load && load.e === null && !load.E) {
      if (load.er)
        return null;
      return load.n;
    }
  };
  systemJSPrototype.set = function(id, module) {
    {
      try {
        new URL(id);
      } catch (err) {
        console.warn(Error(errMsg("W3", '"' + id + '" is not a valid URL to set in the module registry')));
      }
    }
    var ns;
    if (toStringTag && module[toStringTag] === "Module") {
      ns = module;
    } else {
      ns = Object.assign(/* @__PURE__ */ Object.create(null), module);
      if (toStringTag)
        Object.defineProperty(ns, toStringTag, { value: "Module" });
    }
    var done = Promise.resolve(ns);
    var load = this[REGISTRY][id] || (this[REGISTRY][id] = {
      id,
      i: [],
      h: false,
      d: [],
      e: null,
      er: void 0,
      E: void 0
    });
    if (load.e || load.E)
      return false;
    Object.assign(load, {
      n: ns,
      I: void 0,
      L: void 0,
      C: done
    });
    return ns;
  };
  systemJSPrototype.has = function(id) {
    var load = this[REGISTRY][id];
    return !!load;
  };
  systemJSPrototype.delete = function(id) {
    var registry = this[REGISTRY];
    var load = registry[id];
    if (!load || load.p && load.p.e !== null || load.E)
      return false;
    var importerSetters = load.i;
    if (load.d)
      load.d.forEach(function(depLoad) {
        var importerIndex = depLoad.i.indexOf(load);
        if (importerIndex !== -1)
          depLoad.i.splice(importerIndex, 1);
      });
    delete registry[id];
    return function() {
      var load2 = registry[id];
      if (!load2 || !importerSetters || load2.e !== null || load2.E)
        return false;
      importerSetters.forEach(function(setter) {
        load2.i.push(setter);
        setter(load2.n);
      });
      importerSetters = null;
    };
  };
  var iterator = typeof Symbol !== "undefined" && Symbol.iterator;
  systemJSPrototype.entries = function() {
    var loader8 = this, keys = Object.keys(loader8[REGISTRY]);
    var index = 0, ns, key;
    var result = {
      next: function() {
        while ((key = keys[index++]) !== void 0 && (ns = loader8.get(key)) === void 0) ;
        return {
          done: key === void 0,
          value: key !== void 0 && [key, ns]
        };
      }
    };
    result[iterator] = function() {
      return this;
    };
    return result;
  };
})();

// ../../../node_modules/systemjs/dist/extras/named-register.js
(function() {
  (function(global2) {
    var System2 = global2.System;
    setRegisterRegistry(System2);
    var systemJSPrototype = System2.constructor.prototype;
    var constructor = System2.constructor;
    var SystemJS = function() {
      constructor.call(this);
      setRegisterRegistry(this);
    };
    SystemJS.prototype = systemJSPrototype;
    System2.constructor = SystemJS;
    var firstNamedDefine, firstName;
    function setRegisterRegistry(systemInstance) {
      systemInstance.registerRegistry = /* @__PURE__ */ Object.create(null);
      systemInstance.namedRegisterAliases = /* @__PURE__ */ Object.create(null);
    }
    var register = systemJSPrototype.register;
    systemJSPrototype.register = function(name, deps, declare, metas) {
      if (typeof name !== "string")
        return register.apply(this, arguments);
      var define = [deps, declare, metas];
      this.registerRegistry[name] = define;
      if (!firstNamedDefine) {
        firstNamedDefine = define;
        firstName = name;
      }
      Promise.resolve().then(function() {
        firstNamedDefine = null;
        firstName = null;
      });
      return register.apply(this, [deps, declare, metas]);
    };
    var resolve = systemJSPrototype.resolve;
    systemJSPrototype.resolve = function(id, parentURL) {
      try {
        return resolve.call(this, id, parentURL);
      } catch (err) {
        if (id in this.registerRegistry) {
          return this.namedRegisterAliases[id] || id;
        }
        throw err;
      }
    };
    var instantiate = systemJSPrototype.instantiate;
    systemJSPrototype.instantiate = function(url, firstParentUrl, meta) {
      var result = this.registerRegistry[url];
      if (result) {
        this.registerRegistry[url] = null;
        return result;
      } else {
        return instantiate.call(this, url, firstParentUrl, meta);
      }
    };
    var getRegister = systemJSPrototype.getRegister;
    systemJSPrototype.getRegister = function(url) {
      var register2 = getRegister.call(this, url);
      if (firstName && url) {
        this.namedRegisterAliases[firstName] = url;
      }
      var result = firstNamedDefine || register2;
      firstNamedDefine = null;
      firstName = null;
      return result;
    };
  })(typeof self !== "undefined" ? self : global);
})();

// src/utils/empty.ts
var emptyApp = {
  setup() {
  }
};

// ../../tooling/piral-cli/src/common/version.ts
var semver = /^[v^~<>=]*?(\d+)(?:\.([x*]|\d+)(?:\.([x*]|\d+)(?:\.([x*]|\d+))?(?:-([\da-z\-]+(?:\.[\da-z\-]+)*))?(?:\+[\da-z\-]+(?:\.[\da-z\-]+)*)?)?)?$/i;
var acceptsAll = ["*", "x", ">=0"];
var operatorResMap = {
  ">": [1],
  ">=": [0, 1],
  "=": [0],
  "<=": [-1, 0],
  "<": [-1]
};
function indexOrEnd(str, q) {
  return str.indexOf(q) === -1 ? str.length : str.indexOf(q);
}
function splitVersion(v) {
  const c = v.replace(/^v/, "").replace(/\+.*$/, "");
  const patchIndex = indexOrEnd(c, "-");
  const arr = c.substring(0, patchIndex).split(".");
  arr.push(c.substring(patchIndex + 1));
  return arr;
}
function parseSegment(v) {
  const n = parseInt(v, 10);
  return isNaN(n) ? v : n;
}
function validateAndParse(v) {
  const match = v.match(semver);
  match.shift();
  return match;
}
function compareStrings(a, b) {
  const ap = parseSegment(a);
  const bp = parseSegment(b);
  if (ap > bp) {
    return 1;
  } else if (ap < bp) {
    return -1;
  } else {
    return 0;
  }
}
function compareSegments(a, b) {
  for (let i = 0; i < 2; i++) {
    const r = compareStrings(a[i] || "0", b[i] || "0");
    if (r !== 0) {
      return r;
    }
  }
  return 0;
}
function compareVersions(v1, v2) {
  const s1 = splitVersion(v1);
  const s2 = splitVersion(v2);
  const len = Math.max(s1.length - 1, s2.length - 1);
  for (let i = 0; i < len; i++) {
    const m1 = s1[i] || "0";
    const m2 = s2[i] || "0";
    if (m2 === "x") {
      return 0;
    }
    const n1 = parseInt(m1, 10);
    const n2 = parseInt(m2, 10);
    if (n1 > n2) {
      return 1;
    } else if (n2 > n1) {
      return -1;
    }
  }
  const sp1 = s1[s1.length - 1];
  const sp2 = s2[s2.length - 1];
  if (sp1 && sp2) {
    const p1 = sp1.split(".").map(parseSegment);
    const p2 = sp2.split(".").map(parseSegment);
    const len2 = Math.max(p1.length, p2.length);
    for (let i = 0; i < len2; i++) {
      if (p1[i] === void 0 || typeof p2[i] === "string" && typeof p1[i] === "number") {
        return -1;
      } else if (p2[i] === void 0 || typeof p1[i] === "string" && typeof p2[i] === "number") {
        return 1;
      } else if (p1[i] > p2[i]) {
        return 1;
      } else if (p2[i] > p1[i]) {
        return -1;
      }
    }
  } else if (sp1 || sp2) {
    return sp1 ? -1 : 1;
  }
  return 0;
}
function compare(v1, v2, operator) {
  const res = compareVersions(v1, v2);
  return operatorResMap[operator].indexOf(res) > -1;
}
function validate(version) {
  return acceptsAll.includes(version) || semver.test(version);
}
function satisfies(v, r) {
  if (!acceptsAll.includes(r)) {
    const match = r.match(/^([<>=~^]+)/);
    const op = match ? match[1] : "=";
    if (op !== "^" && op !== "~") {
      return compare(v, r, op);
    }
    const [v1, v2, v3] = validateAndParse(v);
    const [m1, m2, m3] = validateAndParse(r);
    if (compareStrings(v1, m1) !== 0) {
      return false;
    } else if (op === "^") {
      return compareSegments([v2, v3], [m2, m3]) >= 0;
    } else if (compareStrings(v2, m2) !== 0) {
      return false;
    }
    return compareStrings(v3, m3) >= 0;
  }
  return true;
}

// src/utils/system.ts
var systemResolve = System.constructor.prototype.resolve;
var systemRegister = System.constructor.prototype.register;
function getLoadedVersions(prefix) {
  return [...System.entries()].filter(([name]) => name.startsWith(prefix)).map(([name]) => name.substring(prefix.length));
}
function findMatchingPackage(id) {
  const sep = id.indexOf("@", 1);
  if (sep > 1) {
    const available = Object.keys(System.registerRegistry);
    const name = id.substring(0, sep + 1);
    const versionSpec = id.substring(sep + 1);
    if (validate(versionSpec)) {
      const loadedVersions = getLoadedVersions(name);
      const allVersions = available.filter((m) => m.startsWith(name)).map((m) => m.substring(name.length));
      const availableVersions = [...loadedVersions, ...allVersions.filter((m) => !loadedVersions.includes(m))];
      for (const availableVersion of availableVersions) {
        if (validate(availableVersion) && satisfies(availableVersion, versionSpec)) {
          return name + availableVersion;
        }
      }
    }
  }
  return void 0;
}
function isPrimitiveExport(content) {
  const type = typeof content;
  return type === "number" || type === "boolean" || type === "symbol" || type === "string" || type === "bigint" || Array.isArray(content);
}
System.constructor.prototype.resolve = function(id, parentUrl) {
  try {
    return systemResolve.call(this, id, parentUrl);
  } catch (ex) {
    const result = findMatchingPackage(id);
    if (!result) {
      throw ex;
    }
    return result;
  }
};
System.constructor.prototype.register = function(...args) {
  const getContent = args.pop();
  args.push((_export, ctx) => {
    const exp = (...p) => {
      if (p.length === 1) {
        const content = p[0];
        if (content instanceof Promise) {
          return content.then(exp);
        } else if (typeof content === "function") {
          _export("__esModule", true);
          Object.keys(content).forEach((prop) => {
            _export(prop, content[prop]);
          });
          _export("default", content);
        } else if (isPrimitiveExport(content)) {
          _export("__esModule", true);
          _export("default", content);
        } else if (content) {
          _export(content);
          if (typeof content === "object" && !("default" in content)) {
            _export("default", content);
          }
        }
      } else {
        return _export(...p);
      }
    };
    return getContent(exp, ctx);
  });
  return systemRegister.apply(this, args);
};
function tryResolve(name, parent) {
  try {
    return System.resolve(name, parent);
  } catch {
    return void 0;
  }
}
function handleFailure(error, link) {
  console.error("Failed to load SystemJS module", link, error);
  return emptyApp;
}
function loadSystemPilet(link) {
  return System.import(link).catch((error) => handleFailure(error, link));
}
function registerDependencies(modules) {
  const moduleNames = Object.keys(modules);
  moduleNames.forEach((name) => registerModule(name, () => modules[name]));
  return Promise.all(moduleNames.map((name) => System.import(name)));
}
function registerModule(name, resolve) {
  System.register(name, [], (_exports) => ({
    execute() {
      const content = resolve();
      if (content instanceof Promise) {
        return content.then(_exports);
      } else {
        _exports(content);
      }
    }
  }));
}
function registerDependencyUrls(dependencies) {
  for (const name of Object.keys(dependencies)) {
    if (!System.has(name)) {
      const dependency = dependencies[name];
      registerModule(name, () => System.import(dependency));
    }
  }
}
function unregisterModules(baseUrl, dependencies) {
  [...System.entries()].map(([name]) => name).filter((name) => name.startsWith(baseUrl) && !dependencies.includes(name)).forEach((name) => System.delete(name));
}
function requireModule(name, parent) {
  const moduleId = tryResolve(name, parent);
  const dependency = moduleId && System.get(moduleId);
  if (!dependency) {
    const error = new Error(`Cannot find module '${name}'`);
    error.code = "MODULE_NOT_FOUND";
    throw error;
  }
  return dependency;
}

// src/utils/helpers.ts
function isfunc(f) {
  return typeof f === "function";
}
function callfunc(f, ...args) {
  isfunc(f) && f(...args);
}
function promisify(value) {
  return Promise.resolve(value);
}
function getBasePath(link) {
  if (link) {
    const idx = link.lastIndexOf("/");
    return link.substring(0, idx + 1);
  }
  return link;
}

// src/utils/dependency.ts
function createEvaluatedPilet(meta, mod) {
  const basePath = getBasePath(meta.link);
  const app = checkPiletApp(meta.name, mod);
  return { ...meta, ...app, basePath };
}
function checkCreateApi(createApi) {
  if (!isfunc(createApi)) {
    console.warn("Invalid `createApi` function. Skipping pilet installation.");
    return false;
  }
  return true;
}
function checkPiletApp(name, app) {
  if (!app) {
    console.error("Invalid module found.", name);
  } else if (typeof app.setup !== "function") {
    console.warn("Setup function is missing.", name);
  } else {
    return app;
  }
  return emptyApp;
}
function checkPiletAppAsync(name, app) {
  return promisify(app).then((resolvedApp) => checkPiletApp(name, resolvedApp));
}
function includeScript(depName, link, integrity, crossOrigin) {
  window[depName] = (moduleId) => requireModule(moduleId, link);
  return includeScriptDependency(link, integrity, crossOrigin).then((s) => s.app);
}
function includeScriptDependency(link, integrity, crossOrigin) {
  return new Promise((resolve, reject) => {
    const s = document.createElement("script");
    s.async = true;
    s.src = link;
    if (integrity) {
      s.crossOrigin = crossOrigin || "anonymous";
      s.integrity = integrity;
    } else if (crossOrigin) {
      s.crossOrigin = crossOrigin;
    }
    s.onload = () => resolve(s);
    s.onerror = (e) => reject(e);
    document.body.appendChild(s);
  });
}

// src/utils/load.ts
var depContext = {};
function loadSharedDependencies(dependencies) {
  if (dependencies) {
    const names = Object.keys(dependencies);
    return Promise.all(
      names.map((name) => {
        return depContext[name] || (depContext[name] = includeScriptDependency(dependencies[name]));
      })
    );
  }
  return promisify();
}
function handleFailure2(error, name) {
  console.error("Failed to load pilet", name, error);
  return emptyApp;
}
function loadFrom(meta, loadPilet) {
  return loadSharedDependencies(meta.dependencies).then(loadPilet).catch((error) => handleFailure2(error, meta.name)).then((app) => createEvaluatedPilet(meta, app));
}

// src/lifecycle/cleanup.ts
var evtName = "unload-pilet";
function runCleanup(app, api, hooks) {
  if (typeof document !== "undefined") {
    const css = document.querySelector(`link[data-origin=${JSON.stringify(app.name)}]`);
    css?.remove();
  }
  const url = app.basePath;
  callfunc(app.teardown, api);
  callfunc(hooks.cleanupPilet, app);
  if ("requireRef" in app) {
    const depName = app.requireRef;
    delete globalThis[depName];
  }
  if (url) {
    unregisterModules(url, Object.values(app.dependencies));
  }
}
function prepareCleanup(app, api, hooks) {
  const handler = (e) => {
    if (e.name === app.name) {
      api.off(evtName, handler);
      runCleanup(app, api, hooks);
    }
  };
  api.on(evtName, handler);
}

// src/lifecycle/setup.ts
function logError(name, e) {
  console.error(`Error while setting up ${name}.`, e);
}
function withCatch(result, name) {
  if (result instanceof Promise) {
    return result.catch((e) => logError(name, e));
  }
  return promisify(result);
}
function setupSinglePilet(app, apiFactory, hooks) {
  const name = app?.name;
  try {
    const api = apiFactory(app);
    callfunc(hooks.setupPilet, app);
    const result = app.setup(api);
    prepareCleanup(app, api, hooks);
    return withCatch(result, name);
  } catch (e) {
    logError(name, e);
  }
  return promisify();
}
function setupPiletBundle(app, apiFactory, hooks) {
  const name = app?.name || "pilet bundle";
  try {
    callfunc(hooks.setupPilet, app);
    const result = app.setup(apiFactory);
    return withCatch(result, name);
  } catch (e) {
    logError(name, e);
  }
  return promisify();
}

// src/inspect.ts
function inspectPilet(meta) {
  const inBrowser = typeof document !== "undefined";
  if ("link" in meta && meta.spec === "v3") {
    return ["v3", meta, setupSinglePilet];
  } else if (inBrowser && "link" in meta && meta.spec === "mf") {
    return ["mf", meta, setupSinglePilet];
  } else if (inBrowser && "link" in meta && meta.spec === "v2") {
    return ["v2", meta, setupSinglePilet];
  } else if (inBrowser && "requireRef" in meta && meta.spec !== "v2") {
    return ["v1", meta, setupSinglePilet];
  } else if (inBrowser && "bundle" in meta && meta.bundle) {
    return ["bundle", meta, setupPiletBundle];
  } else if ("hash" in meta) {
    return ["v0", meta, setupSinglePilet];
  } else {
    return ["unknown", meta, setupSinglePilet];
  }
}

// src/aggregate.ts
function runPilets(createApi, pilets, hooks = {}) {
  const promises = [];
  if (Array.isArray(pilets)) {
    for (const pilet of pilets) {
      const [, , setupPilet] = inspectPilet(pilet);
      const wait = setupPilet(pilet, createApi, hooks);
      promises.push(wait);
    }
  }
  return Promise.all(promises).then(() => pilets);
}
function runPilet(createApi, pilet, hooks = {}) {
  const [, , setupPilet] = inspectPilet(pilet);
  const wait = setupPilet(pilet, createApi, hooks);
  return wait.then(() => pilet);
}

// src/api.ts
function initializeApi(target, events) {
  return {
    on: events.on,
    once: events.once,
    off: events.off,
    emit: events.emit,
    meta: Object.assign({}, target)
  };
}
function mergeApis(api, extenders, target) {
  const frags = extenders.map((extender) => extender(api, target));
  Object.assign(api, ...frags);
  return api;
}

// src/load.ts
function checkFetchPilets(fetchPilets) {
  if (!isfunc(fetchPilets)) {
    console.error("Could not get the pilets. Provide a valid `fetchPilets` function.");
    return false;
  }
  return true;
}
function loadMetadata(fetchPilets) {
  if (checkFetchPilets(fetchPilets)) {
    return fetchPilets().then((pilets) => {
      if (!Array.isArray(pilets)) {
        throw new Error("The fetched pilets metadata is not an array.");
      }
      return pilets.map((meta) => ({ ...meta }));
    });
  }
  return promisify([]);
}
function loadPilets(fetchPilets, loadPilet) {
  return loadMetadata(fetchPilets).then((pilets) => Promise.all(pilets.map(loadPilet)));
}

// src/loaders/bundle/dependency.ts
function includeBundle(meta, crossOrigin) {
  return includeScript(meta.bundle, meta.link, meta.integrity, crossOrigin);
}

// src/loaders/bundle/index.ts
function loader(entry, config) {
  const { dependencies = {}, spec = "v1", name = `[bundle] ${entry.link}`, ...rest } = entry;
  const meta = {
    name,
    version: "",
    config: {},
    spec,
    dependencies,
    ...rest
  };
  return loadFrom(meta, () => includeBundle(entry, config.crossOrigin));
}

// src/loaders/empty/index.ts
function loader2(entry, _config) {
  const { name, spec = "vx", ...rest } = entry;
  const dependencies = "dependencies" in entry ? entry.dependencies : {};
  const meta = {
    name,
    version: "",
    spec,
    dependencies,
    config: {},
    link: "",
    basePath: "",
    ...rest
  };
  console.warn("Empty pilet found!", name);
  return promisify({ ...meta, ...emptyApp });
}

// src/loaders/v0/fetch.ts
function fetchDependency(url) {
  return fetch(url, {
    method: "GET",
    cache: "force-cache"
  }).then((m) => m.text());
}

// src/loaders/v0/dependency.ts
function evalDependency(name, content, link = "") {
  const mod = {
    exports: {}
  };
  try {
    const sourceUrl = link && `
//# sourceURL=${link}`;
    const importer = new Function("module", "exports", "require", content + sourceUrl);
    const parent = link || name;
    const require2 = (moduleId) => requireModule(moduleId, parent);
    importer(mod, mod.exports, require2);
  } catch (e) {
    console.error(`Error while evaluating ${name}.`, e);
  }
  return mod.exports;
}

// src/loaders/v0/index.ts
function loader3(entry, _config) {
  const { name, config = {}, dependencies = {}, spec = "v0" } = entry;
  const meta = {
    name,
    config,
    dependencies,
    spec,
    link: "",
    ...entry
  };
  if ("link" in entry && entry.link) {
    return loadFrom(
      meta,
      () => fetchDependency(entry.link).then((content) => evalDependency(name, content, entry.link))
    );
  } else {
    const content = "content" in entry && entry.content || "";
    return loadFrom(meta, () => evalDependency(name, content, void 0));
  }
}

// src/loaders/v1/dependency.ts
function includeDependency(entry, crossOrigin) {
  return includeScript(entry.requireRef, entry.link, entry.integrity, crossOrigin);
}

// src/loaders/v1/index.ts
function loader4(entry, config) {
  const { dependencies = {}, spec = "v1", ...rest } = entry;
  const meta = {
    dependencies,
    config: {},
    spec,
    ...rest
  };
  return loadFrom(meta, () => includeDependency(entry, config.crossOrigin));
}

// src/loaders/v2/index.ts
function loader5(entry, _config) {
  const { dependencies = {}, config = {}, link, ...rest } = entry;
  const meta = {
    dependencies,
    config,
    link,
    ...rest
  };
  registerDependencyUrls(dependencies);
  return loadSystemPilet(link).then((app) => createEvaluatedPilet(meta, app));
}

// src/loaders/v3/index.ts
function loader6(entry, _config) {
  const { dependencies = {}, config = {}, link, ...rest } = entry;
  const meta = {
    dependencies,
    config,
    link,
    ...rest
  };
  registerDependencyUrls(dependencies);
  return loadSystemPilet(link).then((app) => {
    const pilet = createEvaluatedPilet(meta, app);
    if (Array.isArray(app.styles) && typeof document !== "undefined") {
      for (const style of app.styles) {
        const link2 = document.createElement("link");
        link2.setAttribute("data-origin", pilet.name);
        link2.type = "text/css";
        link2.rel = "stylesheet";
        link2.href = `${pilet.basePath}/${style}`;
        document.head.appendChild(link2);
      }
    }
    return pilet;
  });
}

// src/loaders/mf/index.ts
var appShell = "piral";
function populateKnownDependencies(scope) {
  for (const [entry] of System.entries()) {
    const index = entry.lastIndexOf("@");
    if (index > 0 && !entry.match(/^https?:\/\//)) {
      const entryName = entry.substring(0, index);
      const entryVersion = entry.substring(index + 1);
      if (!(entryName in scope)) {
        scope[entryName] = {};
      }
      scope[entryName][entryVersion] = {
        from: appShell,
        eager: false,
        get: () => System.import(entry).then((result) => () => result)
      };
    }
  }
}
function extractSharedDependencies(scope) {
  for (const entryName of Object.keys(scope)) {
    const entries = scope[entryName];
    for (const entryVersion of Object.keys(entries)) {
      const entry = entries[entryVersion];
      if (entry.from !== appShell) {
        registerModule(`${entryName}@${entryVersion}`, () => entry.get().then((factory) => factory()));
      }
    }
  }
}
function loadMfFactory(piletName, exposedName) {
  const varName = piletName.replace(/^@/, "").replace("/", "-").replace(/\-/g, "_");
  const container = window[varName];
  const scope = {};
  container.init(scope);
  populateKnownDependencies(scope);
  extractSharedDependencies(scope);
  return container.get(exposedName);
}
function loader7(entry, _config) {
  const { config = {}, name, link, ...rest } = entry;
  const dependencies = {};
  const exposedName = rest.custom?.exposed || "./pilet";
  const meta = {
    name,
    dependencies,
    config,
    link,
    ...rest
  };
  return includeScriptDependency(link).then(() => loadMfFactory(name, exposedName)).then((factory) => createEvaluatedPilet(meta, factory()));
}

// src/loader.ts
function extendLoader(fallback, specLoaders) {
  if (typeof specLoaders === "object" && specLoaders) {
    return (meta) => {
      if (typeof meta.spec === "string") {
        const loaderOverride = specLoaders[meta.spec];
        if (isfunc(loaderOverride)) {
          return loaderOverride(meta);
        }
      }
      return fallback(meta);
    };
  }
  return fallback;
}
function getDefaultLoader(config = {}) {
  return (result) => {
    const r = inspectPilet(result);
    switch (r[0]) {
      case "v3":
        return loader6(r[1], config);
      case "v2":
        return loader5(r[1], config);
      case "v1":
        return loader4(r[1], config);
      case "v0":
        return loader3(r[1], config);
      case "mf":
        return loader7(r[1], config);
      case "bundle":
        return loader(r[1], config);
      default:
        return loader2(r[1], config);
    }
  };
}

// src/strategies.ts
function runAll(createApi, existingPilets, additionalPilets, hooks) {
  if (!Array.isArray(existingPilets)) {
    return Promise.reject(`The existing pilets must be passed as an array.`);
  }
  if (!checkCreateApi(createApi)) {
    return Promise.resolve([]);
  }
  try {
    for (const existing of existingPilets) {
      const { name } = existing;
      const [newPilet] = additionalPilets.filter((pilet) => pilet.name === name);
      if (newPilet) {
        additionalPilets.splice(additionalPilets.indexOf(newPilet), 1);
      }
    }
    const pilets = [...existingPilets, ...additionalPilets];
    return runPilets(createApi, pilets, hooks);
  } catch (err) {
    return Promise.reject(err);
  }
}
function createProgressiveStrategy(async) {
  return (options, cb) => {
    const {
      fetchPilets,
      dependencies = {},
      createApi,
      config,
      pilets = [],
      loadPilet = getDefaultLoader(config),
      loaders,
      hooks
    } = options;
    const loadingAll = loadMetadata(fetchPilets);
    const loadSingle = extendLoader(loadPilet, loaders);
    return registerDependencies(dependencies).then(() => {
      if (!checkCreateApi(createApi)) {
        cb(void 0, []);
        return Promise.resolve();
      }
      return runPilets(createApi, pilets, hooks).then((integratedPilets) => {
        if (async && integratedPilets.length > 0) {
          cb(void 0, [...integratedPilets]);
        }
        const followUp = loadingAll.then((metadata) => {
          const promises = metadata.map(
            (m) => loadSingle(m).then((app) => {
              const available = pilets.filter((m2) => m2.name === app.name).length === 0;
              if (available) {
                return runPilet(createApi, app, hooks).then((additionalPilet) => {
                  integratedPilets.push(additionalPilet);
                  if (async) {
                    cb(void 0, [...integratedPilets]);
                  }
                });
              }
            })
          );
          return Promise.all(promises).then(() => {
            if (!async) {
              cb(void 0, integratedPilets);
            }
          });
        });
        if (async) {
          followUp.catch(() => {
          });
          return loadingAll.then();
        } else {
          return followUp.then();
        }
      });
    });
  };
}
function blazingStrategy(options, cb) {
  const strategy = createProgressiveStrategy(true);
  return strategy(options, cb);
}
function asyncStrategy(options, cb) {
  standardStrategy(options, cb);
  return promisify();
}
function standardStrategy(options, cb) {
  const {
    fetchPilets,
    dependencies = {},
    createApi,
    config,
    pilets = [],
    loadPilet = getDefaultLoader(config),
    loaders,
    hooks
  } = options;
  const loadSingle = extendLoader(loadPilet, loaders);
  return registerDependencies(dependencies).then(() => loadPilets(fetchPilets, loadSingle)).then((additionalPilets) => runAll(createApi, pilets, additionalPilets, hooks)).then((integratedPilets) => cb(void 0, integratedPilets)).catch((error) => cb(error, []));
}
function syncStrategy(options, cb) {
  const { createApi, hooks, dependencies = {}, pilets = [] } = options;
  return registerDependencies(dependencies).then(
    () => runAll(createApi, pilets, [], hooks).then(
      (integratedPilets) => cb(void 0, integratedPilets),
      (err) => cb(err, [])
    )
  );
}
function createDeferredStrategy(trigger, strategy = standardStrategy) {
  return (options, cb) => {
    cb(void 0, []);
    trigger.then(() => strategy(options, cb));
    return promisify();
  };
}

// src/create.ts
function startLoadingPilets(options) {
  const state = {
    loaded: false,
    pilets: [],
    error: void 0
  };
  const notifiers = [];
  const call = (notifier) => notifier(state.error, state.pilets, state.loaded);
  const notify = () => notifiers.forEach(call);
  const setPilets = (error, pilets) => {
    state.error = error;
    state.pilets = pilets;
    notify();
  };
  const setLoaded = () => {
    state.loaded = true;
    notify();
  };
  const { strategy = standardStrategy } = options;
  strategy(options, setPilets).then(setLoaded, setLoaded);
  return {
    connect(notifier) {
      if (isfunc(notifier)) {
        notifiers.push(notifier);
        call(notifier);
      }
    },
    disconnect(notifier) {
      const index = notifiers.indexOf(notifier);
      index !== -1 && notifiers.splice(index, 1);
    }
  };
}

// src/events.ts
function nameOf(type) {
  return `piral-${type}`;
}
function createListener(state = {}) {
  const eventListeners = [];
  const events = {
    on(type, callback) {
      const listener = ({ detail }) => detail && detail.state === state && callback(detail.arg);
      document.body.addEventListener(nameOf(type), listener);
      eventListeners.push([callback, listener]);
      return events;
    },
    once(type, callback) {
      const cb = (ev) => {
        events.off(type, cb);
        callback(ev);
      };
      return events.on(type, cb);
    },
    off(type, callback) {
      const [listener] = eventListeners.filter((m) => m[0] === callback);
      if (listener) {
        document.body.removeEventListener(nameOf(type), listener[1]);
        eventListeners.splice(eventListeners.indexOf(listener), 1);
      }
      return events;
    },
    emit(type, arg) {
      document.body.dispatchEvent(
        new CustomEvent(nameOf(type), {
          bubbles: false,
          cancelable: false,
          detail: {
            arg,
            state
          }
        })
      );
      return events;
    }
  };
  return events;
}
export {
  asyncStrategy,
  blazingStrategy,
  callfunc,
  checkCreateApi,
  checkPiletApp,
  checkPiletAppAsync,
  createDeferredStrategy,
  createEvaluatedPilet,
  createListener,
  createProgressiveStrategy,
  emptyApp,
  extendLoader,
  getBasePath,
  getDefaultLoader,
  includeScript,
  includeScriptDependency,
  initializeApi,
  inspectPilet,
  isfunc,
  loadFrom,
  loadMetadata,
  loadPilets,
  loadSystemPilet,
  mergeApis,
  prepareCleanup,
  promisify,
  registerDependencies,
  registerDependencyUrls,
  registerModule,
  requireModule,
  runCleanup,
  runPilet,
  runPilets,
  setupPiletBundle,
  setupSinglePilet,
  standardStrategy,
  startLoadingPilets,
  syncStrategy,
  unregisterModules
};
/*! Bundled license information:

systemjs/dist/system.js:
  (*!
   * SystemJS 6.14.2
   *)
*/
